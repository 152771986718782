<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-2">
			<div class="input-wrap">
				<h5 class="mb-2">Establecimientos</h5>
			</div>
			<div class="action-btn-wrap">
				<v-btn color="accent" @click.prevent="clickCreate()">Nuevo</v-btn>
			</div>
		</div>
		<v-card color="secondary" class="pa-3">
			<emb-data-table-without-filter :resource="resource">
				<tr slot="heading">
					<th>N°</th>
					<th>Nombre</th>
					<th class="text-right">Código</th>
					<th>Email</th>
					<th>Telefono</th>
					<th>Dirección</th>
					<!-- <th>Latitud</th>
                    <th>Longitud</th> -->
					<th class="text-right">Acciones</th>
				</tr>
				<tr slot-scope="{ index, row }" :key="index">
					<td>{{ index }}</td>
					<td>{{ row.name }}</td>
					<td class="text-right">{{ row.code }}</td>
					<td>{{ row.email }}</td>
					<td>{{ row.telephone }}</td>
					<td>{{ row.address }}</td>
					<!-- <td>{{ row.latitude }}</td>
                    <td>{{ row.longitude }}</td> -->

					<td class="text-right">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									color="warning"
									class="ma-1"
									fab
									x-small
									dark
									@click.prevent="clickSeries(row.id)"
									v-on="on"
								>
									<v-icon>mdi-format-list-bulleted</v-icon>
								</v-btn>
							</template>
							<span>Series</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									color="secondary"
									class="ma-1"
									fab
									x-small
									dark
									@click.prevent="clickCreate(row.id)"
									v-on="on"
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
							</template>
							<span>Modificar</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									color="accent"
									class="ma-1"
									fab
									x-small
									dark
									@click.prevent="clickDelete(row.id)"
									v-on="on"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</template>
							<span>Eliminar</span>
						</v-tooltip>
					</td>
				</tr>
			</emb-data-table-without-filter>
		</v-card>

		<establishment-form :showDialog.sync="showDialog" :recordId="recordId"></establishment-form>

		<establishment-series
			:showDialog.sync="showDialogSeries"
			:establishmentId="recordId"
		></establishment-series>
	</div>
</template>

<script>
import EstablishmentForm from './Form.vue';
import { deletable } from 'Mixins/deletable';
import EstablishmentSeries from './Component/Series.vue';

export default {
	mixins: [deletable],
	components: { EstablishmentForm, EstablishmentSeries },
	data() {
		return {
			showDialog: false,
			resource: 'establishments',
			recordId: null,
			records: [],
			showDialogSeries: false,
		};
	},
	created() {},
	
	methods: {
		clickCreate(recordId = null) {
			this.recordId = recordId;
			this.showDialog = true;
		},
		clickSeries(recordId = null) {
			this.recordId = recordId;
			this.showDialogSeries = true;
		},
		clickDelete(id) {
			this.destroy(`/${this.resource}/${id}`).then(() =>
				this.$eventHub.$emit('reloadData', this.resource)
			);
		},
	},
};
</script>
