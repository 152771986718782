<template>
  <el-dialog
    :title="titleDialog"
    :visible="showDialog"
    @close="close"
    @open="create"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="65%"
  >
    <form autocomplete="off" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" xl="8" lg="8" md="6" sm="6">
          <div class="form-group" :class="{ 'error--text': errors.name }">
            <label>Nombre</label>
            <el-input v-model="form.name"></el-input>
            <small
              class="error--text"
              v-if="errors.name"
              v-text="errors.name[0]"
            ></small>
          </div>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="6" sm="6">
          <div class="form-group" :class="{ 'error--text': errors.code }">
            <label>Código Domicilio Fiscal</label>
            <el-input v-model="form.code" :maxlength="4"></el-input>
            <small
              class="error--text"
              v-if="errors.code"
              v-text="errors.code[0]"
            ></small>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <!-- <v-col cols="12" xl="4" lg="6" md="12" sm="12">
                    <div class="form-group" :class="{'error--text': errors.country_id}">
                        <label class="control-label">País</label>
                        <el-select v-model="form.country_id" filterable>
                            <el-option v-for="option in countries" :key="option.id" :value="option.id" :label="option.description"></el-option>
                        </el-select>
                        <small class="error--text" v-if="errors.country_id" v-text="errors.country_id[0]"></small>
                    </div>
                </v-col> -->
        <v-col cols="12" xl="4" lg="4" md="4" sm="12">
          <div
            class="form-group"
            :class="{ 'error--text': errors.department_id }"
          >
            <label class="control-label">Departamento</label>
            <el-select
              v-model="form.department_id"
              filterable
              @change="filterProvince"
            >
              <el-option
                v-for="option in allDepartments"
                :key="option.id"
                :value="option.id"
                :label="option.description"
              ></el-option>
            </el-select>
            <small
              class="error--text"
              v-if="errors.department_id"
              v-text="errors.department_id[0]"
            ></small>
          </div>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12">
          <div
            class="form-group"
            :class="{ 'error--text': errors.province_id }"
          >
            <label class="control-label">Provincia</label>
            <el-select
              v-model="form.province_id"
              filterable
              @change="filterDistrict"
            >
              <el-option
                v-for="option in provinces"
                :key="option.id"
                :value="option.id"
                :label="option.description"
              ></el-option>
            </el-select>
            <small
              class="error--text"
              v-if="errors.province_id"
              v-text="errors.province_id[0]"
            ></small>
          </div>
        </v-col>
        <v-col cols="12" xl="4" lg="4" md="4" sm="12">
          <div
            class="form-group"
            :class="{ 'error--text': errors.district_id }"
          >
            <label class="control-label">Distrito</label>
            <el-select v-model="form.district_id" filterable>
              <el-option
                v-for="option in districts"
                :key="option.id"
                :value="option.id"
                :label="option.description"
              ></el-option>
            </el-select>
            <small
              class="error--text"
              v-if="errors.district_id"
              v-text="errors.district_id[0]"
            ></small>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div class="form-group" :class="{ 'error--text': errors.address }">
            <label class="control-label">Dirección</label>
            <el-input v-model="form.address"></el-input>
            <small
              class="error--text"
              v-if="errors.address"
              v-text="errors.address[0]"
            ></small>
          </div>
        </v-col>
        <!-- <v-col cols="12" xl="3" lg="12" md="12" sm="12">
                    <div class="form-group" :class="{'error--text': errors.latitude}">
                        <label class="control-label">Latitud</label>
                        <el-input v-model="form.latitude" :readonly="true"></el-input>
                        <small class="error--text" v-if="errors.latitude" v-text="errors.latitude[0]"></small>
                    </div>
                </v-col>
                <v-col cols="12" xl="3" lg="12" md="12" sm="12">
                    <div class="form-group" :class="{'error--text': errors.longitude}">
                        <label class="control-label">Longitud</label>
                        <el-input v-model="form.longitude" :readonly="true"></el-input>
                        <small class="error--text" v-if="errors.longitude" v-text="errors.longitude[0]"></small>
                    </div>
                </v-col> -->
        <!-- <v-col cols="12" xl="6" lg="12" md="12" sm="12">
                    <div class="form-group" :class="{'error--text': errors.trade_address}">
                        <label class="control-label">Dirección Comercial</label>
                        <el-input v-model="form.trade_address"></el-input>
                        <small class="error--text" v-if="errors.trade_address" v-text="errors.trade_address[0]"></small>
                    </div> 
                </v-col> -->
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <div class="form-group" :class="{ 'error--text': errors.telephone }">
            <label class="control-label">Teléfono</label>
            <el-input v-model="form.telephone"></el-input>
            <small
              class="error--text"
              v-if="errors.telephone"
              v-text="errors.telephone[0]"
            ></small>
          </div>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <div class="form-group" :class="{ 'error--text': errors.email }">
            <label class="control-label">Correo electrónico</label>
            <el-input v-model="form.email"></el-input>
            <small
              class="error--text"
              v-if="errors.email"
              v-text="errors.email[0]"
            ></small>
          </div>
        </v-col>
        <!-- <v-col cols="12" xl="12" lg="12" md="12" sm="12">
                    <div class="form-group" :class="{'error--text': errors.aditional_information}">
                        <label class="control-label">Información adicional</label>
                        <el-input v-model="form.aditional_information"></el-input>
                        <small class="error--text" v-if="errors.aditional_information" v-text="errors.aditional_information[0]"></small>
                    </div>
                </v-col> -->
        <v-col cols="12" xl="3" lg="3" md="3" sm="6">
          <div
            class="form-group"
            :class="{ 'error--text': errors.currency_type_id }"
          >
            <label class="control-label">Comprobante predeterminado</label>
            <el-select v-model="form.document_type_id">
              <el-option
                v-for="option in documentTypes"
                :key="option.id"
                :value="option.id"
                :label="option.description"
              ></el-option>
            </el-select>
            <small
              class="error--text"
              v-if="errors.document_type_id"
              v-text="errors.document_type_id[0]"
            ></small>
          </div>
        </v-col>
        <v-col cols="12" xl="3" lg="3" md="3" sm="6" v-if="form.id">
          <div class="form-group">
            <label class="control-label">Logo</label>
            <el-input v-model="form.logo" :readonly="true">
              <el-upload
                slot="append"
                :headers="headers"
                :data="{ type: 'logo', id: form.id }"
                :action="`${appUrlBackEnd}/api/establishments/upload-logo`"
                :before-upload="beforeUpload"
                :on-success="successUpload"
                :show-file-list="false"
              >
                <el-button type="primary" icon="el-icon-upload"></el-button>
              </el-upload>
            </el-input>
            <!-- <div class="error--text"><small>Se recomienda resoluciones 700x300</small></div> -->
          </div>
        </v-col>
        <!-- <v-col cols="12" xl="4" lg="12" md="12" sm="12">
                    <div class="form-group" :class="{'error--text': errors.currency_type_id}">
                        <label class="control-label">Moneda predeterminada</label>
                        <el-select v-model="form.currency_type_id">
                            <el-option v-for="option in currencyTypes" :key="option.id" :value="option.id" :label="option.description"></el-option>
                        </el-select>
                        <small class="error--text" v-if="errors.currency_type_id" v-text="errors.currency_type_id[0]"></small>
                    </div>
                </v-col> -->
      </v-row>
      <v-row>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <el-button type="danger" @click.prevent="close()">Cancelar</el-button>
          <el-button
            type="primary"
            native-type="submit"
            :loading="loadingSubmit"
          >
            <template v-if="loadingSubmit">
              Guardando...
            </template>
            <template v-else>
              Guardar
            </template>
          </el-button>
        </v-col>
      </v-row>
    </form>
  </el-dialog>
</template>

<script>
import AppConfig from "Constants/AppConfig";
import { confirm } from "Mixins/confirm";
import dayjs from "dayjs";

export default {
  props: ["showDialog", "recordId"],
  mixins: [confirm],
  data() {
    return {
      loadingSubmit: false,
      titleDialog: null,
      loadingSearch: false,
      resource: "establishments",
      errors: {},
      form: {},
      countries: [],
      allDepartments: [],
      allProvinces: [],
      allDistricts: [],
      provinces: [],
      districts: [],
      documentTypes: [],
      currencyTypes: [],
      printerSizes: [],
      printOfFormats: [],
      appUrlBackEnd: AppConfig.appUrlBackEnd,
      headers: {
        Authorization: null,
      },
    };
  },
  created() {
    //  this.initMap()
  },
  methods: {
    async getTables() {
      await this.$http
        .get(`/${this.resource}/static-tables`)
        .then((response) => {
          this.countries = response.data.countries;
          this.allDepartments = response.data.departments;
          this.allProvinces = response.data.provinces;
          this.allDistricts = response.data.districts;
          this.documentTypes = response.data.documentTypes;
          this.currencyTypes = response.data.currencyTypes;

          this.form.document_type_id =
            this.documentTypes.length > 0 ? this.documentTypes[0].id : null;
          this.form.currency_type_id =
            this.currencyTypes.length > 0 ? this.currencyTypes[0].id : null;

          if (this.recordId) {
            this.getRecord();
          }
        });
    },
    initForm() {
      this.errors = {};
      this.customers = [];
      this.form = {
        id: null,
        name: null,
        country_id: "PE",
        department_id: null,
        province_id: null,
        district_id: null,
        address: null,
        trade_address: null,
        latitude: null,
        longitude: null,
        telephone: null,
        email: null,
        code: null,
        document_type_id: null,
        currency_type_id: null,
        logo: null,
      };
    },
    async create() {
      this.titleDialog = this.recordId
        ? "Editar establecimiento"
        : "Nuevo establecimiento";
      this.initForm();
      // this.initMap()
      await this.getTables();
    },
    async getRecord() {
      await this.$http
        .get(`/${this.resource}/record/${this.recordId}`)
        .then((response) => {
          this.form = response.data.data;

          this.filterProvinces();
          this.filterDistricts();
        });
    },
    setTime(timePicker) {
      this.form.execution_time = `${dayjs(timePicker).format("HH:mm")}:00`;
    },
    async beforeUpload(file) {
      const accessToken = localStorage.getItem('access_token')

      this.headers.Authorization = `Bearer ${accessToken}`;

      const isIMG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/svg+xml" ||
        file.type === "image/jpg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isIMG) this.$message.error("La imagen no es valida!");
      if (!isLt2M) this.$message.error("La imagen excede los 2MB!");

      return isIMG && isLt2M;
    },
    successUpload(response) {
      if (response.success) {
        this.$message.success(response.message);
        this.form[response.type] = response.name;
      } else {
        this.$message({ message: "Error al subir el archivo", type: "error" });
      }
    },
    submit() {
      this.confirm().then(() => {
        this.loading_submit = true;
        this.$http
          .post(`/${this.resource}`, this.form)
          .then((response) => {
            if (response.data.success) {
              this.$message.success(response.data.message);
              this.$eventHub.$emit("reloadData", this.resource);
              this.close();
            } else {
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
            } else {
              this.$message.error(error.response.data.message);
            }
          })
          .then(() => {
            this.loading_submit = false;
          });
      });
    },
    filterProvince() {
      this.form.province_id = null;
      this.form.district_id = null;
      this.filterProvinces();
    },
    filterProvinces() {
      this.provinces = this.allProvinces.filter((f) => {
        return f.department_id === this.form.department_id;
      });
    },
    filterDistrict() {
      this.form.district_id = null;
      this.filterDistricts();
    },
    filterDistricts() {
      this.districts = this.allDistricts.filter((f) => {
        return f.province_id === this.form.province_id;
      });
    },
    close() {
      this.$emit("update:showDialog", false);
      this.initForm();
    },
  },
};
</script>
